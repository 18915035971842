import * as React from "react";
import { observer, inject } from "mobx-react";
import {
  Destination,
  DestinationSuggestionsFlexModuleResult,
} from "typings/microserviceModels/destination-suggestions-flex-module";
import { DestinationSuggestionsProps } from "./typings";
import { WeekendGetaways } from "./WeekendGetaways/WeekendGetaways";
import { Embedding } from "./Embedding/Embedding";
import { EmbeddingRedesign } from "./Embedding/EmbeddingRedesign";
import { reaction } from "mobx";
import { setCookie } from "src/components/utility/cookie";
import { getFmId } from "src/stores/ExperienceTemplateStore";

export enum CookieKeys {
  WEEKEND_GETAWAYS = "weekendGetaways",
}

@inject("compositionStore", "cookies", "destinationSuggestions", "flexModuleModelStore")
@observer
export class DestinationSuggestions extends React.Component<DestinationSuggestionsProps, {}> {
  private model = this.getModel();

  /* istanbul ignore next */
  public disposer = reaction(
    () => this.props.destinationSuggestions && this.props.destinationSuggestions.destinationSuggestionsOrigin,
    (destinationSuggestionsOrigin) => {
      if (destinationSuggestionsOrigin && this.props.compositionStore) {
        const fmId = getFmId(this.props.templateComponent);

        const promise = this.props.compositionStore.updateModule({
          fmId: Number(fmId),
          locationUserId: destinationSuggestionsOrigin,
        });

        promise.then((result) => {
          if (result.status === 200 && this.model) {
            setCookie({
              key: CookieKeys.WEEKEND_GETAWAYS,
              value: JSON.stringify({
                dstn_sgstn_org_id: destinationSuggestionsOrigin,
              }),
            });
          }
        });
      }
    }
  );

  public componentDidMount() {
    if (this.props.compositionStore && !this.model?.disableAutoLoad) {
      const savedData = JSON.parse(this.props.cookies!.getCookie(CookieKeys.WEEKEND_GETAWAYS)!);
      const fmId = getFmId(this.props.templateComponent);

      let destinationSuggestionOrigin = this.props.destinationSuggestions?.destinationSuggestionsOrigin;
      if (savedData) {
        destinationSuggestionOrigin = savedData.dstn_sgstn_org_id;
      }
      this.props.compositionStore.updateModule({
        fmId: Number(fmId),
        locationUserId: destinationSuggestionOrigin,
      });
    }
  }

  public componentWillUnmount() {
    this.disposer();
  }

  private hasDestinations(destinations: Destination[]): boolean {
    return destinations && destinations.length > 0;
  }

  private getModel() {
    const { flexModuleModelStore, templateComponent } = this.props;
    if (!flexModuleModelStore || !templateComponent) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as DestinationSuggestionsFlexModuleResult | null;
    return model;
  }

  public render() {
    const view = this.props.templateComponent?.config?.view;

    if (!view) {
      return null;
    }

    // Supported views by the component
    const localGetAwaysViews: any = {
      weekendGetaways: <WeekendGetaways {...this.props} />,
      destinationEmbedding: <Embedding {...this.props} />,
      embeddingRedesign: <EmbeddingRedesign {...this.props} />,
    };

    if (!this.model || !this.hasDestinations(this.model.destinations)) {
      return null;
    }

    return localGetAwaysViews[view] ? localGetAwaysViews[view] : null; // Do not render anything;
  }
}

export default DestinationSuggestions;
