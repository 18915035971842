import * as React from "react";
import { observer } from "mobx-react";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { UitkScrim, UitkScrimType } from "@egds/react-core/scrim";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkCard, UitkCardLink, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkText, UitkTextProps, UitkSubheading } from "@egds/react-core/text";

import { DestinationSuggestionsProps } from "../typings";
import {
  Destination,
  DestinationSuggestionsFlexModuleResult,
} from "typings/microserviceModels/destination-suggestions-flex-module";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { IsomorphicCarousel } from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { useLocalization } from "@shared-ui/localization-context";
import {
  PageBackgroundContext,
  BackgroundColorTokens,
} from "components/flexComponents/PageLevelProviders/PageBackgroundColor/PageBackgroundContext";
import { Heading } from "../Heading/Heading";
import { BlossomImage } from "components/shared/BlossomImage/BlossomImage";
import { UitkSpacing } from "@egds/react-core/spacing";
import { getFmId } from "src/stores/ExperienceTemplateStore";

const computeDistance = (driveDistanceinMeters: string) => {
  if (!driveDistanceinMeters || driveDistanceinMeters === "0" || driveDistanceinMeters === "-1") {
    return "";
  }
  const distInKm = Math.round(parseInt(driveDistanceinMeters, 10) / 1000);
  const hours = Math.floor(distInKm / 100);
  const minutes = Math.round((distInKm % 100) * 0.6);
  const { formatText } = useLocalization();

  if (hours === 0) {
    return formatText("destinationSuggestions.distance.minutesAway", minutes);
  }
  if (hours === 1) {
    if (minutes < 2) {
      return formatText("destinationSuggestions.distance.hoursAway", 1);
    }

    return formatText("destinationSuggestions.distance.hoursAndMinutesAway", 1, minutes);
  }
  if (hours <= 7) {
    if (minutes < 2) {
      return formatText("destinationSuggestions.distance.hoursAway", hours);
    }

    return formatText("destinationSuggestions.distance.hoursAndMinutesAway", hours, minutes);
  }

  return null;
};

const WeekendGetawaysCards = ({
  destination,
  displayDescription,
  displayCost,
  displayWeather,
  displayDistance,
  cardIndex,
}: {
  destination: Destination;
  displayDescription: boolean;
  displayCost: boolean;
  displayWeather: boolean;
  displayDistance: boolean;
  cardIndex: number;
}) => {
  const avgTime = displayDistance && computeDistance(destination.driveDistanceinMeters);
  const rfrrId = cardIndex + 1;
  const { formatText } = useLocalization();

  const backgroundColor = React.useContext(PageBackgroundContext);
  const withBorder = backgroundColor === BackgroundColorTokens.BACKGROUND_WHITE;
  const {
    destinationImg,
    imgAlt,
    name,
    destinationGeo,
    destinationWeather,
    destinationDescriptions,
    averageLodgingCost,
    url,
  } = destination;
  const { inPos, state } = destinationGeo;
  const heading = `${name} ${inPos && state ? `, ${state}` : ""}`;
  const weatherInformation =
    destinationWeather?.unit === "°F"
      ? formatText("destinationSuggestions.weatherInformation.F", destinationWeather?.avgTemp)
      : formatText("destinationSuggestions.weatherInformation.C", destinationWeather?.avgTemp);
  const subHeading = destinationWeather ? weatherInformation : "";
  const { SHORT: shortDescription } = destinationDescriptions;
  const averageLodgingCostText = formatText("destinationSuggestions.averageCost", averageLodgingCost);

  return (
    <UitkCard border={withBorder}>
      <UitkFigure ratio={UitkFigureAspectRatioType.R21_9}>
        <BlossomImage src={destinationImg} alt={imgAlt} />
        <UitkScrim type={UitkScrimType.BOTTOM}>
          <UitkSubheading tag="h3">{heading}</UitkSubheading>
          {displayWeather && destinationWeather && destinationWeather.avgTemp && (
            <UitkSubheading tag="h4">{subHeading}</UitkSubheading>
          )}
        </UitkScrim>
      </UitkFigure>

      {(displayDescription || displayCost) && (
        <UitkCardContentSection>
          {displayDescription && <DetailsText padded={false}>{shortDescription}</DetailsText>}
          {displayCost && averageLodgingCost && <DetailsText weight="bold">{averageLodgingCostText}</DetailsText>}
          {displayDistance && avgTime && (
            <DetailsText>
              <UitkIcon className="distance-icon" size={UitkIconSize.SMALL} name="directions_car" />
              <UitkSpacing padding={{ inlinestart: "one" }}>
                <span>{avgTime}</span>
              </UitkSpacing>
            </DetailsText>
          )}
        </UitkCardContentSection>
      )}

      <UitkCardLink>
        <TrackedLink moduleName="DestinationSuggestions" action={Action.CLICK} href={url} rfrr={rfrrId}>
          {name}
        </TrackedLink>
      </UitkCardLink>
    </UitkCard>
  );
};

export const WeekendGetaways = observer((props: DestinationSuggestionsProps) => {
  const { templateComponent, flexModuleModelStore } = props;

  if (!templateComponent || !flexModuleModelStore) {
    return null;
  }

  const { metadata } = templateComponent;
  const { id } = metadata;
  const model = flexModuleModelStore.getModel(id) as DestinationSuggestionsFlexModuleResult | null;

  /* istanbul ignore next */
  if (!model) {
    return null;
  }

  const keyHelper = new ItemKeyHelper(id);
  const {
    month,
    origin,
    destinations,
    disableOriginSelector,
    displayLodgingCost,
    displayWeather,
    displayDescriptions,
    displayDistanceTime,
  } = model;
  if (destinations.length < 4) {
    return null;
  }

  // Display description only if all the cards have description in the required format.
  const displayDescription =
    displayDescriptions &&
    destinations.filter(({ destinationDescriptions }) => destinationDescriptions && destinationDescriptions.SHORT)
      .length === destinations.length;

  const { formatText } = useLocalization();
  const fmId = getFmId(templateComponent);

  return (
    <UitkSpacing margin={{ blockend: "three" }}>
      <div data-testid="weekend-getaways" className="DestinationSuggestions" id={id} data-fm={fmId}>
        <Heading month={month} origin={origin} disableOriginSelector={disableOriginSelector} />
        <IsomorphicCarousel
          className="carousel-component"
          itemsVisible={{
            lg: 3,
            md: 2,
            sm: 1,
          }}
          buttonText={{
            nextButton: formatText("carousel.item.next"),
            prevButton: formatText("carousel.item.prev"),
          }}
          pageBy={1}
          peek
        >
          {destinations.map((destination, index) => {
            return (
              <WeekendGetawaysCards
                key={keyHelper.next()}
                destination={destination}
                displayDescription={displayDescription}
                displayCost={displayLodgingCost}
                displayWeather={displayWeather}
                displayDistance={displayDistanceTime}
                cardIndex={index}
              />
            );
          })}
        </IsomorphicCarousel>
      </div>
    </UitkSpacing>
  );
});
(WeekendGetaways as React.FC).displayName = "WeekendGetawaysView";

interface DetailsTextProps {
  padded?: boolean;
  weight?: UitkTextProps["weight"];
}

const DetailsText: React.FC<DetailsTextProps> = ({ children, padded = true, weight = "regular" }) => (
  <UitkSpacing padding={padded ? { blockstart: "two" } : undefined}>
    <UitkText size={300} weight={weight}>
      {children}
    </UitkText>
  </UitkSpacing>
);
