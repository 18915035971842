import * as React from "react";
import { OriginSelector } from "./OriginSelector";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText, UitkHeading } from "@egds/react-core/text";

export interface DestinationSuggestionsHeadingProps {
  origin: string;
  month: string;
  disableOriginSelector: boolean;
}

export const Heading: React.SFC<DestinationSuggestionsHeadingProps> = (props: DestinationSuggestionsHeadingProps) => {
  const { origin, month, disableOriginSelector } = props;

  let finalSubtitle;
  const { formatText } = useLocalization();
  const title = formatText("destinationSuggestions.title");
  const subtitle = formatText(`destinationSuggestions.subtitle.${month}`, "{{origin}}");

  if (subtitle) {
    const subtitleParts = subtitle.split("{{origin}}");
    // Inject the OriginSelector component in the removed origin template
    finalSubtitle = [
      subtitleParts[0],
      <OriginSelector key="origin-selector" origin={origin} disableOriginSelector={disableOriginSelector} />,
      subtitleParts[1],
    ];
  }

  return (
    <UitkSpacing padding={{ small: { inlinestart: "three" }, large: { inlinestart: "unset" } }}>
      <div>
        <UitkSpacing padding={{ blockstart: "three" }}>
          <div className="title-wrapper">
            <UitkHeading tag="h2" size={3}>
              {title}
            </UitkHeading>
          </div>
        </UitkSpacing>
        <UitkSpacing margin={{ blockend: "three" }}>
          <div className="subtitle-wrapper">
            {finalSubtitle && (
              <UitkText className="subtitle" size={300}>
                {finalSubtitle}
              </UitkText>
            )}
          </div>
        </UitkSpacing>
      </div>
    </UitkSpacing>
  );
};
