import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { UitkLink } from "@egds/react-core/link";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { TypeaheadAdapter } from "@egds/react-core/typeahead";
import { UitkSheetTransition } from "@egds/react-core/sheet";
import { withStores } from "src/stores";
import { DestinationSuggestionsStore } from "src/stores/DestinationSuggestionsStore";
import { TypeaheadDialogData } from "../typings";
import { useLocalization } from "@shared-ui/localization-context";
import { useLazyTypeahead, TypeaheadFallbackInput } from "utils/hooks/useLazyTypeahead";

export interface OriginSelectorProps {
  origin: string;
  disableOriginSelector: boolean;
  context: ExtendedContextStore;
  destinationSuggestions: DestinationSuggestionsStore;
}

export const OriginSelector = withStores(
  "context",
  "destinationSuggestions"
)((props: OriginSelectorProps) => {
  const { origin, disableOriginSelector, destinationSuggestions, context } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { typeaheadModule } = useLazyTypeahead();
  const [isDialogOpen, dialogActions, OriginSelectorDialogComponent] = useDialog("origin-selector-dialog");
  const { formatText } = useLocalization();
  const taLabelToken = "wizard.package.origin.label";

  /* istanbul ignore next */
  const handleOriginSelection = (term: string, data: TypeaheadDialogData) => {
    destinationSuggestions.setOriginForDestinationSuggestions(data.regionId);
  };

  const closeOriginDialog = () => {
    dialogActions.closeDialog();
  };

  const openDialog = () => {
    dialogActions.openDialog();
  };

  if (!typeaheadModule) {
    return <TypeaheadFallbackInput label={formatText(taLabelToken)} />;
  }

  const { ESSAdapter, UitkTypeaheadDialog } = typeaheadModule;

  const essAdapter: TypeaheadAdapter = new ESSAdapter({
    client: "HotelsLandingPage",
    dest: false,
    features: "ta_hierarchy|postal_code",
    guid: context.guid,
    inputId: "origin-selector",
    lob: "HOTELS",
    locale: context.locale,
    maxResults: 7,
    regionType: 4,
    siteId: context.site.id,
  });

  return disableOriginSelector ? (
    <span>{origin}</span>
  ) : (
    <>
      <UitkLink inline onClick={openDialog}>
        <a>{origin}</a>
      </UitkLink>

      <UitkSheetTransition isVisible={isDialogOpen}>
        <OriginSelectorDialogComponent>
          <UitkTypeaheadDialog
            adapter={essAdapter}
            allowExactMatch
            dialog
            label={formatText(taLabelToken)}
            onDismiss={closeOriginDialog}
            onResultItemSelect={handleOriginSelection}
            term={origin}
            isMulti={false}
            typeaheadInputRef={inputRef}
          />
        </OriginSelectorDialogComponent>
      </UitkSheetTransition>
    </>
  );
});

export default OriginSelector;
