import * as React from "react";
import { observer } from "mobx-react";

import { useLocalization } from "@shared-ui/localization-context";

import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { UitkScrim, UitkScrimType } from "@egds/react-core/scrim";
import { UitkCard, UitkCardLink } from "@egds/react-core/cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkHeading, UitkSubheading } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";

import { DestinationSuggestionsProps } from "../typings";
import {
  Destination,
  DestinationSuggestionsFlexModuleResult,
} from "typings/microserviceModels/destination-suggestions-flex-module";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { IsomorphicCarousel } from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { BlossomImage } from "components/shared/BlossomImage/BlossomImage";

import {
  PageBackgroundContext,
  BackgroundColorTokens,
} from "components/flexComponents/PageLevelProviders/PageBackgroundColor/PageBackgroundContext";
import { withStores } from "stores";
import { getFmId } from "src/stores/ExperienceTemplateStore";

const EmbeddingCards = ({ destination, cardIndex }: { destination: Destination; cardIndex: number }) => {
  const rfrrId = cardIndex + 1;

  const backgroundColor = React.useContext(PageBackgroundContext);
  const withBorder = backgroundColor === BackgroundColorTokens.BACKGROUND_WHITE;
  const { destinationImg, imgAlt, name, url } = destination;

  return (
    <UitkCard border={withBorder}>
      <UitkFigure ratio={UitkFigureAspectRatioType.R3_2}>
        <BlossomImage src={destinationImg} alt={imgAlt} />
        <UitkScrim type={UitkScrimType.BOTTOM}>
          <UitkLayoutFlex justifyContent="space-between">
            <UitkLayoutFlexItem>
              <div>
                <UitkSubheading tag="h3">{name}</UitkSubheading>
              </div>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkScrim>
      </UitkFigure>
      <UitkCardLink>
        <TrackedLink moduleName="Embedding" action={Action.CLICK} href={url} rfrr={rfrrId}>
          {name}
        </TrackedLink>
      </UitkCardLink>
    </UitkCard>
  );
};

export const Embedding = withStores("flexModuleModelStore")(
  observer((props: DestinationSuggestionsProps) => {
    const { templateComponent, flexModuleModelStore } = props;
    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as DestinationSuggestionsFlexModuleResult | null;

    /* istanbul ignore next */
    if (!model) {
      return null;
    }

    const keyHelper = new ItemKeyHelper(id);
    const { formatText } = useLocalization();
    const title = formatText("destinationSuggestions.embedding.title.v2");
    const subtitle = formatText("destinationSuggestions.embedding.title");
    const fmId = getFmId(templateComponent);

    return (
      <UitkSpacing margin={{ blockend: "three" }}>
        <div data-testid="embedding" className="DestinationSuggestions" id={id} data-fm={fmId}>
          <UitkSpacing padding={{ small: { inlinestart: "three", block: "three" }, large: { inlinestart: "unset" } }}>
            <div>
              <UitkHeading tag="h2" size={3}>
                {title}
              </UitkHeading>
              <UitkSubheading tag="h3">{subtitle}</UitkSubheading>
            </div>
          </UitkSpacing>
          <IsomorphicCarousel
            className="carousel-component"
            itemsVisible={{
              lg: 3,
              md: 2,
              sm: 1,
            }}
            buttonText={{
              nextButton: formatText("carousel.item.next"),
              prevButton: formatText("carousel.item.prev"),
            }}
            pageBy={1}
            peek
          >
            {model.destinations.map((destination, index) => (
              <EmbeddingCards key={keyHelper.next()} destination={destination} cardIndex={index} />
            ))}
          </IsomorphicCarousel>
        </div>
      </UitkSpacing>
    );
  })
);
(Embedding as React.FC).displayName = "EmbeddingView";
