import * as React from "react";
import { observer } from "mobx-react";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { DestinationSuggestionsProps } from "../typings";
import {
  Destination,
  DestinationSuggestionsFlexModuleResult,
} from "src/typings/microserviceModels/destination-suggestions-flex-module";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { IsomorphicCarousel } from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkHeading, UitkSubheading } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";
import { withStores } from "stores";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";

const EmbeddingRedesignCard = ({ destination, cardIndex }: { destination: Destination; cardIndex: number }) => {
  const rfrrId = cardIndex + 1;

  return (
    <UitkLayoutFlex justifyContent="start" direction="column">
      <UitkCard className="EmbeddingCard">
        <UitkCardContentSection padded={false}>
          <UitkFigure ratio={UitkFigureAspectRatioType.R16_9} className="uitk-card-roundcorner-all">
            <BlossomImage src={destination.destinationImg} alt={destination.imgAlt} />
          </UitkFigure>
          <UitkCardLink>
            <TrackedLink moduleName="Embedding" action={Action.CLICK} href={destination.url} rfrr={rfrrId}>
              {destination.name}
            </TrackedLink>
          </UitkCardLink>
        </UitkCardContentSection>
        <UitkSpacing padding={{ blockstart: "one" }}>
          <UitkHeading tag="h3" size={6}>
            {destination.name}
          </UitkHeading>
        </UitkSpacing>
      </UitkCard>
    </UitkLayoutFlex>
  );
};

export const EmbeddingRedesign = withStores("flexModuleModelStore")(
  observer((props: DestinationSuggestionsProps) => {
    const { templateComponent, flexModuleModelStore } = props;
    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as DestinationSuggestionsFlexModuleResult | null;

    /* istanbul ignore next */
    if (!model) {
      return null;
    }

    const keyHelper = new ItemKeyHelper(id);
    const { formatText } = useLocalization();
    const title = formatText("destinationSuggestions.embedding.title.v2");
    const subtitle = formatText("destinationSuggestions.embedding.title");
    const fmId = getFmId(templateComponent);

    return (
      <UitkSpacing padding={{ blockend: "three" }}>
        <div data-testid="embeddingRedesign" id={id} data-fm={fmId}>
          <UitkSpacing padding={{ blockend: "three", blockstart: "three" }}>
            <div>
              <UitkHeading tag="h2" size={3}>
                {title}
              </UitkHeading>
              <UitkSubheading tag="h3">{subtitle}</UitkSubheading>
            </div>
          </UitkSpacing>
          <IsomorphicCarousel
            className="carouselComponent"
            itemsVisible={{
              lg: 4,
              md: 4,
              sm: 2,
            }}
            buttonText={{
              nextButton: formatText("carousel.item.next"),
              prevButton: formatText("carousel.item.prev"),
            }}
            pageBy={1}
            peek
          >
            {model.destinations.map((destination, index) => {
              return (
                <div key={keyHelper.next()}>
                  <EmbeddingRedesignCard destination={destination} cardIndex={index} />
                </div>
              );
            })}
          </IsomorphicCarousel>
        </div>
      </UitkSpacing>
    );
  })
);
(EmbeddingRedesign as React.FC).displayName = "EmbeddingRedesignView";
